////////////////////////////////////////////////////////////////////////////////
//
// _ICONS.SCSS
//
// Auto generated icon mixins / functions
//
////////////////////////////////////////////////////////////////////////////////

@font-face {
	font-family: 'icons';
	font-style: normal;
	font-weight: 400;
	src: url($font-path + 'icons.woff2') format('woff2'), url($font-path + 'icons.woff') format('woff');
}

@function icon-char($filename, $fallback) {
	$char: $fallback;

	@if $filename == about {
		$char: '\EA01';
	} @else if $filename == certificate {
		$char: '\EA02';
	} @else if $filename == code {
		$char: '\EA03';
	} @else if $filename == education {
		$char: '\EA04';
	} @else if $filename == email {
		$char: '\EA05';
	} @else if $filename == expertise {
		$char: '\EA06';
	} @else if $filename == facebook {
		$char: '\EA07';
	} @else if $filename == landscape-to-portrait {
		$char: '\EA08';
	} @else if $filename == linkedin {
		$char: '\EA09';
	} @else if $filename == project {
		$char: '\EA0A';
	} @else if $filename == twitter {
		$char: '\EA0B';
	}

	@return $char;
}

@mixin icon-styles {
	font-family: 'icons';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: none;
	text-transform: none;
	speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin icon($filename, $fallback: '', $insert: before) {
	&:#{$insert} {
		@include icon-styles;

		content: icon-char($filename, $fallback);
		@content;
	}
}
