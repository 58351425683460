////////////////////////////////////////////////////////////////////////////////
//
// _EXPERTISE.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.expertise-list {
	counter-reset: expertise;

	@include mq-min($bp-ipad-landscape) {
		display: flex;
		flex-wrap: wrap;
	}
}

.expertise-item {
	position: relative;
	padding-left: 2.5em;
	counter-increment: expertise;

	@include mq-min($bp-ipad-landscape) {
		flex-basis: 50%;
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		color: $color-boston-blue;
		font-size: 1.375em;
		font-weight: 700;
		line-height: 1;
		content: counters(expertise, '.', decimal-leading-zero);
	}

	&:first-child {
		margin-top: 27px;

		@include mq-min($bp-ipad-portrait) {
			margin-top: 17px;
		}

		@include mq-min($bp-ipad-landscape) {
			margin-top: 12px;
		}
	}

	&:last-child {
		margin-bottom: 25px;

		@include mq-min($bp-ipad-portrait) {
			margin-bottom: 14px;
		}

		&:nth-child(odd) {
			@include mq-min($bp-ipad-landscape) {
				flex-basis: 100%;
			}
		}
	}

	&:nth-child(odd) {
		@include mq-min($bp-ipad-landscape) {
			padding-right: $base-spacing-unit;
		}
	}

	& + & {
		@include mq-min($bp-ipad-landscape) {
			margin-top: 12px;
		}
	}
}

.expertise__title {
	.expertise-item & {
		@include mq-min($bp-ipad-landscape) {
			margin-top: 0;
		}
	}

	.expertise-item:first-child & {
		margin-top: 27px;

		@include mq-min($bp-ipad-portrait) {
			margin-top: 17px;
		}

		@include mq-min($bp-ipad-landscape) {
			margin-top: 0;
		}
	}
}

.expertise__text {
	&:last-child {
		margin-bottom: 17px;

		@include mq-min($bp-ipad-landscape) {
			margin-bottom: 4px;
		}

		.expertise-item:last-child & {
			margin-bottom: 25px;

			@include mq-min($bp-ipad-portrait) {
				margin-bottom: 14px;
			}

			@include mq-min($bp-ipad-landscape) {
				margin-bottom: 0;
			}
		}

		.expertise-item:nth-last-child(2) & {
			@include mq-min($bp-ipad-landscape) {
				margin-bottom: 0;
			}
		}
	}
}
