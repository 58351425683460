////////////////////////////////////////////////////////////////////////////////
//
// _HEADER.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.page-header {
	position: relative;
	padding: $base-spacing-unit;
	border-bottom: 1px solid darken($color-river-bed-blue, 5%);
	background-color: $color-river-bed-blue;
	text-align: center;

	@include mq-min($bp-ipad-portrait) {
		height: 300px;
		padding: 0;
		text-align: left;
	}
}

.page-title {
	margin: 0 0 $base-spacing-unit;
	color: $color-sun-yellow;
	font-size: 10vw;
	text-transform: uppercase;

	@include mq-min($bp-ipad-portrait) {
		position: absolute;
		right: $base-spacing-unit;
		bottom: 50%;
		left: $base-spacing-unit;
		margin-bottom: 0;
		padding-bottom: 8px;
		padding-left: 100% - $left-col-width-bp-ipad-width;
		border-bottom: 1px solid $color-satin-linen-yellow;
		font-size: 3.5em;

		&::after {
			position: absolute;
			right: 0;
			bottom: -3px;
			left: 0;
			border-top: 1px solid $color-sun-yellow;
			content: ' ';
		}
	}

	@include mq-min($bp-ipad-landscape) {
		padding-left: 100% - $left-col-width-bp-ipad-height;
	}
}

.page-title__emphasis {
	display: block;
	color: $color-satin-linen-yellow;
	text-transform: lowercase;
}

.page-subtitle {
	display: inline-block;
	position: relative;
	margin: 0 0 $base-spacing-unit;
	color: $color-satin-linen-yellow;
	font-family: $font-family-code;
	font-size: 5vw;
	font-weight: 400;
	line-height: 1.25;

	@include mq-min($bp-ipad-portrait) {
		position: absolute;
		top: 50%;
		left: $base-spacing-unit;
		margin-top: 7px;
		margin-bottom: 0;
		padding-left: 100% - $left-col-width-bp-ipad-width;
		font-size: 1.4375em;
	}

	@include mq-min($bp-ipad-landscape) {
		padding-left: 100% - $left-col-width-bp-ipad-height;
	}

	&::after {
		position: absolute;
		top: 50%;
		right: -6vw;
		transform: translateY(-50%);
		color: darken($color-river-bed-blue, 5%);
		font-size: 8vw;
		text-shadow: 1px 0 0 lighten($color-river-bed-blue, 5%);
		content: '&';

		@include mq-min($bp-ipad-portrait) {
			right: -4vw;
			font-size: 5vw;
		}

		@include mq-min($bp-ipad-landscape) {
			font-size: 4.375rem;
		}
	}

	span {
		display: block;
	}
}
