////////////////////////////////////////////////////////////////////////////////
//
// _EDUCATION.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.course__text {
	&:last-child {
		margin-bottom: 14px;
	}

	.education-item:last-child & {
		margin-bottom: 25px;
	}
}
