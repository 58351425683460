////////////////////////////////////////////////////////////////////////////////
//
// _MIXINS.SCSS
//
// Declare your mixins here
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
//
////////////////////////////////////////////////////////////////////////////////

@mixin clearfix($pseudo: after) {
	&::#{$pseudo} {
		display: table;
		clear: both;
		content: ' ';
	}
}

@mixin visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

// =============================================================================
// TYPOGRAPHY
// =============================================================================

@mixin font-smoothing($value: on) {
	@if $value == on {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	} @else {
		-moz-osx-font-smoothing: auto;
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

// =============================================================================
// HYPERLINKS
// =============================================================================

@mixin hover {
	&:hover {
		@content;
	}
}

@mixin plain-visited {
	&,
	&:visited {
		@content;
	}
}

@mixin hover-focus {
	&:focus,
	&:hover {
		@content;
	}
}

@mixin hover-focus-active {
	&:focus,
	&:active,
	&:hover {
		@content;
	}
}

@mixin plain-hover-focus {
	&,
	&:focus,
	&:hover,
	&:visited {
		@content;
	}
}

@mixin plain-hover-focus-active {
	&,
	&:focus,
	&:active,
	&:hover,
	&:visited {
		@content;
	}
}

// =============================================================================
// GRID
// =============================================================================

@mixin mq-min($width) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin mq-max($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin mq-mobile-landscape {
	@media only screen and (max-device-height: $bp-ipad-portrait - 1) and (orientation: landscape) {
		@content;
	}
}

@mixin mq-iphone5($orientation: both) {
	@include mq-iphone($bp-iphone5-portrait, $bp-iphone5-landscape, $orientation) {
		@content;
	}
}

@mixin mq-iphone6($orientation: both) {
	@include mq-iphone($bp-iphone6-portrait, $bp-iphone6-landscape, $orientation) {
		@content;
	}
}

@mixin mq-iphone6plus($orientation: both) {
	@include mq-iphone($bp-iphone6plus-portrait, $bp-iphone6plus-landscape, $orientation) {
		@content;
	}
}

@mixin mq-iphones($orientation: both) {
	@include mq-iphone5($orientation) {
		@content;
	}

	@include mq-iphone6($orientation) {
		@content;
	}

	@include mq-iphone6plus($orientation) {
		@content;
	}
}

@mixin mq-ipadretina($orientation: both) {
	@include mq-ipad($bp-ipad-portrait, $bp-ipad-landscape, $orientation) {
		@content;
	}
}

@mixin mq-ipads($orientation: both) {
	@include mq-ipadretina($orientation) {
		@content;
	}
}

@mixin mq-iphone($width, $height, $orientation: both) {
	@if $orientation == both {
		@media only screen and (min-device-width: $width) and (max-device-width: $height) {
			@content;
		}
	} @else {
		@media only screen and (min-device-width: $width) and (max-device-width: $height) and (orientation: $orientation) {
			@content;
		}
	}
}

@mixin mq-ipad($width, $height, $orientation: both) {
	@if $orientation == both {
		@media only screen and (min-device-width: $width) and (max-device-width: $height) and (-webkit-min-device-pixel-ratio: 2) {
			@content;
		}
	} @else {
		@media only screen and (min-device-width: $width) and (max-device-width: $height) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: 2) {
			@content;
		}
	}
}
