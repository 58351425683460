////////////////////////////////////////////////////////////////////////////////
//
// _VARIABLES.SCSS
//
//  Declare your vars here
//  http://sass-lang.com/documentation/file.SASS_REFERENCE.html#variables_
//
////////////////////////////////////////////////////////////////////////////////

$base-font-size: 16px;
$default-border-radius: 5px;
$image-path: '../img/';

// =============================================================================
// TYPOGRAPHY
// =============================================================================

$font-path: '../font/';
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: monospace;

// webfonts
$icons: 'icons';
$opensans: 'Open Sans', $font-family-sans-serif;
$opensanscondensed: 'Open Sans Condensed', $font-family-serif;
$spacemono: 'Inconsolata', $font-family-monospace;

// abstract
$font-family-base: $opensans;
$font-family-secondary: $font-family-serif;
$font-family-title: $opensanscondensed;
$font-family-icons: $icons;
$font-family-code: $spacemono;

// =============================================================================
// COLORS
// =============================================================================

$transparent: rgba(255, 255, 255, 0);

$color-white: #fff;
$color-black: #000;

$color-shark-black: #1f2024;
$color-tuna-gray: #33373d;
$color-stack-gray: #858784;
$color-french-gray: #c3c3c5;
$color-gallery-gray: #efefef;
$color-corduroy-green: #606e68;
$color-cloudy-gray: #aca59f;
$color-ship-gray: #414043;
$color-ghost-gray: #c7cbd0;
$color-regent-gray: #9098a1;
$color-jumbo-gray: #737277;

$color-boston-blue: #3891a6;
$color-river-bed-blue: #4c5b5c;
$color-sun-yellow: #f8bb10;
$color-satin-linen-yellow: #e9e7da;
$color-roman-red: #db5461;
$color-terracotta-red: #e3655b;
$color-alabaster-white: #fafafa;
$color-mirage-blue: #1b2735;
$color-cinder-blue: #090a0f;

$default-text-color: $color-river-bed-blue;
$default-border-color: rgba($color-black, .15);


// =============================================================================
// GRID
// =============================================================================

$base-spacing-unit: 15px;
$double-spacing-unit: $base-spacing-unit * 2;
$half-spacing-unit: $base-spacing-unit / 2;
$quarter-spacing-unit: $half-spacing-unit / 2;

$base-text-spacing-unit: 10px;
$double-text-spacing-unit: $base-text-spacing-unit * 2;
$half-text-spacing-unit: $base-text-spacing-unit / 2;

$bp-xs: 320px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1366px;
$bp-xxxl: 1920px;

$bp-iphone5-portrait: 320px;
$bp-iphone5-landscape: 568px;
$bp-iphone6-portrait: 375px;
$bp-iphone6-landscape: 667px;
$bp-iphone6plus-portrait: 414px;
$bp-iphone6plus-landscape: 736px;
$bp-ipad-portrait: 768px;
$bp-ipad-landscape: 1024px;

$left-col-width-bp-ipad-width: 60%;
$left-col-width-bp-ipad-height: 70%;
$profile-picture-width-bp-ipad: 180px;


// =============================================================================
// MODULES
// =============================================================================

$chapter-header-bg-color: $color-alabaster-white;
$chapter-header-border-color: $color-gallery-gray;
$chapter-header-fg-color: inherit;
$chapter-header-icon-color: $color-gallery-gray;

$chapter-title-fg-color: $color-terracotta-red;

$footnote-border-color: $color-gallery-gray;
$footnote-link-color: $color-boston-blue;

$konami-top: 2000px;
$konami-moon-crater-color: $color-ship-gray;

$timeline-width: 2.5rem;
$timeline-bullet-size: 2rem;
$timeline-line-width: 2px;
$timeline-color: $color-gallery-gray;
