////////////////////////////////////////////////////////////////////////////////
//
// _ERROR.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.error {
	width: 100%;
	max-width: $bp-sm;
	margin: 0 auto;
}

.error__title {
	font-size: 2em;
}
