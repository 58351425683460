////////////////////////////////////////////////////////////////////////////////
//
// _PART.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.period-item {
	position: relative;
	margin-top: 27px;
	padding-left: $timeline-width;

	&::before {
		$size: calc(#{$timeline-width} - #{$base-spacing-unit});

		position: absolute;
		top: 15px;
		left: 0;
		width: $size;
		height: $size;
		transition: 500ms ease-in-out color;
		background-color: $color-white;
		color: $color-sun-yellow;
		font-size: $timeline-bullet-size;
		line-height: calc(#{$size} - 2px);
		text-align: center;
		content: '\2022';

		@include mq-min($bp-ipad-landscape) {
			top: 16px;
		}
	}

	&:hover::before {
		color: $color-boston-blue;
	}

	&:first-child {
		@include mq-min($bp-ipad-portrait) {
			margin-top: 17px;
		}
	}

	&:last-child {
		margin-bottom: 25px;

		@include mq-min($bp-ipad-portrait) {
			margin-bottom: 16px;
		}
	}

	.chapter:last-of-type &:last-child {
		&::after {
			@include mq-min($bp-ipad-portrait) {
				position: absolute;
				top: 40px;
				bottom: -25px;
				left: 0;
				width: 30px;
				background: linear-gradient(transparent, $color-white);
				content: ' ';
			}
		}
	}
}
