////////////////////////////////////////////////////////////////////////////////
//
// _CHAPTER.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.chapter {
	margin: 24px 0;

	@include mq-min($bp-ipad-portrait) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-top: 0;
		margin-bottom: 0;
	}

	&:first-of-type {
		margin-top: 0;
	}

	&--timeline {
		position: relative;
		margin-bottom: 0;
		padding-bottom: .1px; // padding - margin hack

		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc(#{$base-spacing-unit} + ((#{$timeline-width} - #{$base-spacing-unit}) / 2) - #{$timeline-line-width});
			border-left: $timeline-line-width solid $timeline-color;
			content: ' ';

			@include mq-min($bp-ipad-portrait) {
				top: 40px;
				left: calc(#{100% - $left-col-width-bp-ipad-width} + #{$base-spacing-unit} + ((#{$timeline-width} - #{$base-spacing-unit}) / 2) - #{$timeline-line-width});
				border-color: lighten($color-boston-blue, 40%);
			}

			@include mq-min($bp-ipad-landscape) {
				left: calc(#{100% - $left-col-width-bp-ipad-height} + #{$base-spacing-unit} + ((#{$timeline-width} - #{$base-spacing-unit}) / 2) - 1px);
			}
		}

		&:last-of-type::before {
			@include mq-min($bp-ipad-portrait) {
				top: 0;
			}
		}
	}

	&--timeline + & {
		margin-top: 0;
	}
}

.chapter__header {
	position: relative;
	padding: 20px $base-spacing-unit 28px;
	border-top: 1px solid $chapter-header-border-color;
	border-bottom: 1px solid $chapter-header-border-color;
	background-color: $chapter-header-bg-color;
	color: $chapter-header-fg-color;

	@include mq-min($bp-ipad-portrait) {
		flex-basis: 40%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-top: 0;
		padding-bottom: 0;
		border-top: 0;
		border-right: 1px solid $chapter-header-border-color;
		border-bottom: 0;
	}

	@include mq-min($bp-ipad-landscape) {
		flex-basis: 30%;
	}

	@include mq-min($bp-xl) {
		text-align: right;
	}

	&::after {
		position: absolute;
		top: 50%;
		right: $base-spacing-unit;
		transform: translateY(-50%);
		color: $chapter-header-icon-color;
		font-size: 4.5em;
		line-height: 1;

		@include mq-min($bp-ipad-portrait) {
			top: 5px;
			transform: none;
		}

		@include mq-min($bp-xl) {
			top: 77px;
			font-size: 5em;
		}

		@include mq-min($bp-xxl) {
			top: 2px;
			right: 270px;
			font-size: 120px;
		}

		@include mq-min($bp-xxxl) {
			font-size: 180px;
		}
	}

	.chapter--education & {
		@include icon('education', 's', 'after') {
			@include mq-min($bp-ipad-landscape) {
				top: 25px;
			}

			@include mq-min($bp-xl) {
				top: 98px;
			}

			@include mq-min($bp-xxl) {
				top: 0;
			}
		}
	}

	.chapter--expertise & {
		@include icon('expertise', 'e', 'after');
	}

	.chapter--me & {
		border-top-width: 0;

		@include mq-min($bp-xl) {
			padding-top: 15px;
		}

		@include icon('about', 'a', 'after') {
			@include mq-min($bp-ipad-portrait) {
				top: 16px;
			}

			@include mq-min($bp-ipad-landscape) {
				top: 12px;
			}

			@include mq-min($bp-xl) {
				top: 90px;
			}

			@include mq-min($bp-xxl) {
				top: 0;
			}
		}
	}

	.chapter--projects & {
		@include icon('project', 'p', 'after') {
			@include mq-min($bp-ipad-landscape) {
				top: 25px;
			}

			@include mq-min($bp-xl) {
				top: 105px;
			}

			@include mq-min($bp-xxl) {
				top: 0;
			}
		};
	}

	.chapter--skills & {
		@include icon('code', 's', 'after');
	}
}

.chapter__title {
	margin: 0;
	color: $chapter-title-fg-color;
	font-size: 2em;

	@include mq-min($bp-ipad-portrait) {
		margin-top: 10px;
	}

	@include mq-min($bp-xl) {
		margin-top: -3px;
		font-size: 2.5em;
	}

	.chapter--me & {
		@include mq-min($bp-ipad-portrait) {
			margin-top: 20px;

			@include mq-min($bp-xl) {
				margin-top: 4px;
			}
		}
	}

	.chapter--timeline & {
		@include mq-min($bp-ipad-landscape) {
			margin-top: 30px;
		}

		@include mq-min($bp-xl) {
			margin-top: 18px;
		}
	}
}

.chapter__title-suffix {
	display: block;
	margin-top: 5px;
	color: $default-text-color;
	font-size: 1.125rem;
	font-weight: 300;

	@include mq-min($bp-xl) {
		font-size: 1.4375rem;
	}
}

.chapter__subtitle {
	margin-top: 17px;
	margin-bottom: 9px;
	font-size: 1.375em;

	&:first-child {
		margin-top: 27px;
	}
}

.chapter__content {
	padding: 0 $base-spacing-unit;

	@include mq-min($bp-ipad-portrait) {
		flex-basis: 100%;
	}

	@include mq-min($bp-xl) {
		padding-right: 20%;
	}

	.chapter--me & {
		@include mq-min($bp-xl) {
			padding-top: 15px;
		}
	}
}

.chapter__text {
	margin-top: 9px;
	margin-bottom: 15px;

	&:first-child {
		margin-top: 21px;
	}

	& + & {
		margin-top: 15px;
	}

	&:last-child,
	&:last-of-type {
		margin-bottom: 25px;
	}
}
