////////////////////////////////////////////////////////////////////////////////
//
// _PART.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.part-list {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

.part-item {
	& + & {
		margin-top: 17px;
	}
}

.part__title {
	margin-top: 8px;
	margin-bottom: 9px;
	font-size: 1.375em;

	.part-item & {
		margin-top: 17px;
	}

	.part__period + & {
		margin-top: 6px;
	}

	&:not(:last-child) {
		margin-bottom: 8px;
	}
}

.part__subtitle {
	margin-top: 8px;
	margin-bottom: 9px;
	font-size: 1.25em;
	font-weight: 400;
}

.part__period {
	display: block;
	margin-top: 23px;
	margin-bottom: 6px;
	padding-left: 2px;
	font-size: .625em;
	line-height: 1;

	.part-item:first-child & {
		@include mq-min($bp-ipad-portrait) {
			margin-top: 17px;
		}
	}
}

.part-divider {
	width: 75%;
	margin: 25px auto;
}
