////////////////////////////////////////////////////////////////////////////////
//
// _WEBFONTS.SCSS
//
////////////////////////////////////////////////////////////////////////////////

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url($font-path + 'OpenSans.woff2') format('woff2'), url($font-path + 'OpenSans.woff') format('woff');
}


@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 300;
	src: local('Open Sans Condensed Light'), local('OpenSans-CondensedLight'), url($font-path + 'OpenSansCondensed-Light.woff2') format('woff2'), url($font-path + 'OpenSansCondensed-Light.woff') format('woff');
}

@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 700;
	src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url($font-path + 'OpenSansCondensed-Bold.woff2') format('woff2'), url($font-path + 'OpenSansCondensed-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Inconsolata';
	font-style: normal;
	font-weight: 400;
	src: local('Inconsolata'), local('Inconsolata-Regular'), url($font-path + 'Inconsolata.woff2') format('woff2'), url($font-path + 'Inconsolata.woff') format('woff');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
