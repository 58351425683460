////////////////////////////////////////////////////////////////////////////////
//
// _FOOTER.SCSS
//
////////////////////////////////////////////////////////////////////////////////

@import '../variables';

.page-footer {
	padding: 0 $base-spacing-unit $half-spacing-unit;
	background-color: $color-river-bed-blue;
	color: $color-satin-linen-yellow;
	text-align: center;

	@media only screen and (max-width: 767px) and (orientation: landscape) {
		width: 50%;
		float: right;
	}
}

.copyright {
	margin: 0;
	font-size: .8125em;
}
