////////////////////////////////////////////////////////////////////////////////
//
// _SOCIAL.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.social {
	display: flex;
	justify-content: center;

	.page-header & {
		@include mq-min($bp-ipad-portrait) {
			position: absolute;
			bottom: 4px;
			left: $base-spacing-unit;
			padding-left: 100% - $left-col-width-bp-ipad-width;
		}

		@include mq-min($bp-ipad-landscape) {
			padding-left: 100% - $left-col-width-bp-ipad-height;
		}
	}
}

.social__list {
	display: flex;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

.social__item {
	margin: 4.25vw;
	line-height: 1;

	@include mq-min($bp-ipad-portrait) {
		margin: 1.5em .75em 1em;

		.page-header &:first-child {
			margin-left: 0;
		}
	}
}

.social__link {
	transition: color 400ms ease;
	font-size: 10vw;
	line-height: 1;
	vertical-align: top;

	@include mq-min($bp-ipad-portrait) {
		font-size: 3em;
	}

	@include plain-visited {
		color: $color-boston-blue;
	}

	@include hover-focus-active {
		color: $color-sun-yellow;
	}

	.social__item--facebook & {
		@include icon('facebook', 'F');
	}

	.social__item--linked-in & {
		@include icon('linkedin', 'L');
	}

	.social__item--email & {
		@include icon('email', 'M');
	}

	.social__item--twitter & {
		@include icon('twitter', 'T');
	}
}

.social__text {
	@include visually-hidden;
}
