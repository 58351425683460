////////////////////////////////////////////////////////////////////////////////
//
// _FOOTNOTE.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.footnotes {
	margin-top: 25px;
	margin-bottom: 27px;
	padding-top: 6px;
	padding-left: 0;
	border-top: 1px solid $footnote-border-color;
	list-style-type: none;
	counter-reset: footnote;

	@include mq-min($bp-ipad-portrait) {
		margin-bottom: 17px;
	}
}

.footnote {
	position: relative;
	padding-left: 1.5em;
	font-size: .75em;
	line-height: 1.25;
	counter-increment: footnote;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		color: $footnote-link-color;
		content: counters(footnote, '.');
	}

	& + & {
		margin-top: 5px;
	}
}

.footnote-reference {
	top: -1em;
	font-size: .5em;
}

.footnote-reference__link {
	@include plain-visited {
		color: $footnote-link-color;
	}

	@include hover-focus-active {
		color: darken($footnote-link-color, 5%);
	}
}
