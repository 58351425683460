////////////////////////////////////////////////////////////////////////////////
//
// _LAYOUT.SCSS
//
// This file includes all LAYOUT styles
//
// SMACSS definition:
// ==================
// The major components are referred to as Layout styles.
////////////////////////////////////////////////////////////////////////////////

html {
	// @include mq-iphones() {
	// 	height: 100%;
	// 	overflow-y: scroll;
	// 	-webkit-overflow-scrolling: touch;
	// }

	@include mq-mobile-landscape {
		height: 100%;
		overflow: hidden;
	}
}

.layout {
	color: $default-text-color;

	@include mq-mobile-landscape {
		position: relative;
		height: 100%;
		background-color: $color-river-bed-blue;
		color: $color-satin-linen-yellow;

		@include icon('landscape-to-portrait', 'R') {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 25vh;
		}

		* {
			display: none;
		}
	}
}

.divider {
	display: block;
	height: 1px;
	margin: 0 0 $base-spacing-unit;
	border-width: 0;
	background-color: $color-french-gray;

	.page-section__text:last-of-type + & {
		margin-top: $double-spacing-unit;
	}
}

.branded-color {
	color: $color-french-gray;
}

.text-emphasize {
	color: darken($color-river-bed-blue, 5%);
}

.methodology-name {
	font-style: normal;
}

.product-name {
	font-style: normal;
}

.technology-name {
	font-style: normal;
}

// all includes in alphabetical ordering
// except when it's necessary for the cascade (files marked with *)

@import 'footer';
@import 'header';
@import 'navigation';
