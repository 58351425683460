////////////////////////////////////////////////////////////////////////////////
//
// _ANIMATIONS.SCSS
//
//  Declare your animations here
//
////////////////////////////////////////////////////////////////////////////////

@keyframes animateStars {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(-2000px);
	}
}

@keyframes scaleMoon {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(3);
	}
}

@keyframes smoke_size {
	0% {
		transform: scale(0);
	}

	20% {
		transform: scale(.5);
	}

	40% {
		transform: scale(.75);
	}

	60% {
		transform: scale(1);
	}

	80% {
		transform: scale(.75);
	}

	90% {
		transform: scale(.5);
	}

	100% {
		transform: scale(.25);
	}
}

@keyframes shadow_flare {
	from {
		fill: $color-black;
	}

	to {
		fill: $color-white;
	}
}
