////////////////////////////////////////////////////////////////////////////////
//
// _REBOOT.SCSS
//
////////////////////////////////////////////////////////////////////////////////

@import 'node_modules/normalize-css/normalize';

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	@include font-smoothing(on);

	font-family: $font-family-base;
	font-size: $base-font-size;
	line-height: 1.5;
}

a {
	text-decoration: none;

	@include plain-visited {
		color: $color-roman-red;
	}

	@include hover-focus-active {
		color: darken($color-roman-red, 10%);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-title;
	font-weight: 700;
	line-height: 1;
}

p {
	margin: 1em 0;
	line-height: 1.5;
}

picture {
	display: block;

	img {
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}
