////////////////////////////////////////////////////////////////////////////////
//
// _PROFILE.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.profile-picture {
	position: relative;
	width: 50vw;
	height: 0;
	margin: $base-spacing-unit auto;
	padding-top: 50vw;
	border-radius: 50%;
	box-shadow: 0 0 50vw lighten($color-river-bed-blue, 5%);
	overflow: hidden;

	@include mq-min($bp-ipad-portrait) {
		position: absolute;
		top: $base-spacing-unit;
		top: 50%;
		right: calc((#{$left-col-width-bp-ipad-width} + #{(100% - $left-col-width-bp-ipad-width) / 2}) - #{$profile-picture-width-bp-ipad / 2} - #{$base-spacing-unit / 2});
		width: $profile-picture-width-bp-ipad;
		margin: 0;
		padding-top: $profile-picture-width-bp-ipad;
		transform: translateY(-50%);
		border: 1px solid $color-sun-yellow;
		border-radius: 25%;
		z-index: 1;
	}

	@include mq-min($bp-ipad-landscape) {
		right: calc((#{$left-col-width-bp-ipad-height} + #{(100% - $left-col-width-bp-ipad-height) / 2}) - #{$profile-picture-width-bp-ipad / 2} - #{$base-spacing-unit / 2});
	}
}

.profile-picture__photo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	filter: sepia(1) brightness(.75);
}
