////////////////////////////////////////////////////////////////////////////////
//
// _PROJECT.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.project-list {
	margin-top: 4px;
}

.project__title {
	margin: 0;
}

.project__skills {
	@include clearfix;

	margin-top: 25px;
	margin-bottom: 16px;
	padding-left: 0;
	list-style-type: none;

	.project-item:last-child & {
		margin-bottom: 24px;

		@include mq-min($bp-ipad-portrait) {
			margin-bottom: 16px;
		}
	}
}

.project__skill {
	display: inline-block;
	margin: 0 4px 4px 0;
	padding: 0 7px;
	float: left;
	border-radius: 3px;
	font-size: .875em;

	&--methodology,
	&--technology {
		background-color: $color-roman-red;
		color: darken($color-roman-red, 25%);
		text-shadow: 1px 1px 0 lighten($color-roman-red, 10%);
	}

	&--platform {
		background-color: $color-boston-blue;
		color: darken($color-boston-blue, 25%);
		text-shadow: 1px 1px 0 lighten($color-boston-blue, 10%);
	}

	&--tool {
		background-color: $color-sun-yellow;
		color: darken($color-sun-yellow, 25%);
		text-shadow: 1px 1px 0 lighten($color-sun-yellow, 10%);
	}
}
