////////////////////////////////////////////////////////////////////////////////
//
// _SKILL.SCSS
//
////////////////////////////////////////////////////////////////////////////////

.skill {
	margin: 16px 0;
}

.skill__title {
	margin-top: 16px;
	margin-bottom: $quarter-spacing-unit;
	font-family: $font-family-code;
	font-size: 1em;
	font-weight: 400;
}

.skill__progress {
	display: block;
	width: 100%;
	height: 5px;
	margin: $quarter-spacing-unit 0 0;
	border: 1px solid $color-boston-blue;
	border-radius: $default-border-radius;
	background-color: $color-gallery-gray;
	background-size: auto;
	color: $color-boston-blue;
	appearance: none;

	@include mq-min($bp-ipad-landscape) {
		height: 10px;
	}

	&::-webkit-progress-bar {
		border-radius: $default-border-radius;
		background-color: lighten($color-boston-blue, 40%);

		.skill-divider ~ .skill & {
			background-color: lighten($color-sun-yellow, 40%);
		}
	}

	&::-moz-progress-bar {
		background-color: $color-boston-blue;

		.skill-divider ~ .skill & {
			background-color: $color-sun-yellow;
		}
	}

	&::-webkit-progress-value {
		background-color: $color-boston-blue;

		.skill-divider ~ .skill & {
			background-color: $color-sun-yellow;
		}
	}

	.skill-divider ~ .skill & {
		border-color: $color-sun-yellow;
		color: $color-sun-yellow;
	}
}

.skills-group {
	margin-top: 22px;

	&:first-child {
		@include mq-min($bp-ipad-portrait) {
			margin-top: 12px;
		}
	}

	&:last-child {
		margin-bottom: 30px;

		@include mq-min($bp-ipad-portrait) {
			margin-bottom: 20px;
		}
	}
}

.skills-group__title {
	margin-top: 22px;
	margin-bottom: 16px;

	&:first-child {
		@include mq-min($bp-ipad-portrait) {
			margin-top: 12px;
		}
	}
}

.skill-divider {
	margin-top: 30px;
}
